var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    ref: "overview-tab",
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Item details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.item_name.id,
      "value": _vm.data.item_name,
      "label": _vm.controls.item_name.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": false
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.category.id,
      "value": _vm.data.category,
      "loading": _vm.controls.category.loading,
      "label": _vm.controls.category.label,
      "readonly": true,
      "options": _vm.controls.category.options,
      "allow-empty": true,
      "multiple": false,
      "mode": _vm.mode,
      "required": false
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.lot_number.id,
      "value": _vm.data.lot_number,
      "label": _vm.controls.lot_number.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": false
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.created.id,
      "value": _vm.data.created,
      "label": _vm.controls.created.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified.id,
      "value": _vm.data.modified,
      "label": _vm.controls.modified.label,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "12",
      "sm": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_remaining.id,
      "value": _vm.data.amount_remaining,
      "label": _vm.controls.amount_remaining.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": false
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "ct_inventory_item_history",
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    }
  })], 1)], 1), _c('hr')], 1), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.mashes.title
    }
  }, [_c('mashes-table', {
    attrs: {
      "lotNumber": _vm.lot_number,
      "show-add-button": false
    },
    on: {
      "loaded": _vm.onMashesLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.fermentations.title
    }
  }, [_c('fermentation-table', {
    attrs: {
      "lotNumber": _vm.lot_number,
      "show-add-button": false
    },
    on: {
      "loaded": _vm.onFermentationsTableLoad
    }
  })], 1) : _vm._e(), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.distillations.title
    }
  }, [_c('distillation-table', {
    attrs: {
      "lotNumber": _vm.lot_number,
      "show-add-button": false
    },
    on: {
      "loaded": _vm.onDistillationsTableLoad
    }
  })], 1) : _vm._e(), _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.barrels_filling.title
    }
  }, [_c('barrel-filling-table', {
    attrs: {
      "lotNumber": _vm.lot_number,
      "show-add-button": false
    },
    on: {
      "": _vm.onBarrelFillingLoad
    }
  })], 1)], 1), _vm.action !== 'preview' ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }