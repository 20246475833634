<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab ref="overview-tab" title="Overview" active>
          <form>
            <h5>
              Item details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>
            <hr />
            <b-row>
              <b-col lg="3" md="12" sm="12">
                <inline-input
                  :id="controls.item_name.id"
                  :value="data.item_name"
                  :label="controls.item_name.label"
                  :readonly="true"
                  :mode="mode"
                  :required="false"
                />
              </b-col>
              <b-col lg="3" md="12" sm="12">
                <inline-select
                  :id="controls.category.id"
                  :value="data.category"
                  :loading="controls.category.loading"
                  :label="controls.category.label"
                  :readonly="true"
                  :options="controls.category.options"
                  :allow-empty="true"
                  :multiple="false"
                  :mode="mode"
                  :required="false"
                />
              </b-col>
              <b-col lg="3" md="12" sm="12">
                <inline-input
                  :id="controls.lot_number.id"
                  :value="data.lot_number"
                  :label="controls.lot_number.label"
                  :readonly="true"
                  :mode="mode"
                  :required="false"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created.id"
                  :value="data.created"
                  :label="controls.created.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified.id"
                  :value="data.modified"
                  :label="controls.modified.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="12" sm="12">
                <inline-input
                  :id="controls.amount_remaining.id"
                  :value="data.amount_remaining"
                  :label="controls.amount_remaining.label"
                  :readonly="true"
                  :mode="mode"
                  :required="false"
                />
              </b-col>
            </b-row>
          </form>
          <hr />

          <b-row>
            <b-col>
              <table-custom
                ref="dataTable"
                name="ct_inventory_item_history"
                :loading="dataTable.isLoading"
                :data="dataTable.dataSet"
                :options="dataTable.options"
              >
              </table-custom>
            </b-col>
          </b-row>

          <hr />
        </b-tab>
        <!--    
        <b-tab
          :title="controls.tabs.orders.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <orders-table :lotNumber="lot_number" @loaded="onOrdersLoad" />
        </b-tab>
      -->
        <b-tab :title="controls.tabs.mashes.title"
           v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <mashes-table
            :lotNumber="lot_number"
            :show-add-button="false"
            @loaded="onMashesLoad"
          />
        </b-tab>
        <b-tab
        :title="controls.tabs.fermentations.title"
        v-if="mode !== $constants.FORM_MODE.CREATE"
      >
        <fermentation-table
         
          :lotNumber="lot_number"
             :show-add-button="false"
          @loaded="onFermentationsTableLoad"
        ></fermentation-table>
      </b-tab>
      <b-tab
        :title="controls.tabs.distillations.title"
        v-if="mode !== $constants.FORM_MODE.CREATE"
      >
        <distillation-table

          :lotNumber="lot_number"
          :show-add-button="false"
          @loaded="onDistillationsTableLoad"
        ></distillation-table>
      </b-tab>      
        <b-tab :title="controls.tabs.barrels_filling.title">
          <barrel-filling-table
            :lotNumber="lot_number"
            :show-add-button="false"
            @="onBarrelFillingLoad"
          ></barrel-filling-table>
        </b-tab>
      </b-tabs>
      <form-submission-actions
        v-if="action !== 'preview'"
        :mode="mode"
        :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
        :buttons-visibility="{
          previous: $customTable.getPrevKey($route.meta.module, id),
          next: $customTable.getNextKey($route.meta.module, id)
        }"
        @previous-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getPrevKey($route.meta.module, id)
            }
          })
        "
        @next-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getNextKey($route.meta.module, id)
            }
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
      />
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'
import FormSubmissionActions from '@/components/FormSubmissionActions'
import TableCustom from '@/components/TableCustom'

import CKEditor from '@ckeditor/ckeditor5-vue'
import InlineEditor from '@ckeditor/ckeditor5-build-inline'

Vue.use(CKEditor)
import moment from 'moment'
import OrdersTable from '@/views/Inventory/OrdersTable'
import MashesTable from '@/views/Production/Mashing/MashingTable.vue'
import FermentationTable from '@/views/Production/Fermentation/FermentationTable.vue'
import DistillationTable from '@/views/Production/Distillation/DistillationTable.vue'
import BarrelFillingTable from '@/views/Production/BarrelFilling/BarrelFillingTable.vue'

import { mapState, mapActions } from 'vuex'
export default {
  name: 'InventoryDetailsForm',
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    lot_number: {
      type: [String, Number],
      default: ''
    },
    action: {
      type: String,
      default: ''
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    FormSubmissionActions,
    TableCustom,

    OrdersTable,
    MashesTable,
    FermentationTable,
    DistillationTable,
    BarrelFillingTable
  },
  data: function () {
    return {
      itemId: undefined,

      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      mode: undefined,
      editor: {
        mode: InlineEditor,
        data: '',
        config: {
          startupFocus: true,
          startupShowBorders: true
          // readOnly: true,
        },
        readonly: false
      },

      controls: {
        tabs: {
          orders: {
            title: 'Purchases'
          },
          mashes: {
            title: 'Mashes'
          },

          fermentations: {
            title: "Fermentations"
          },
          distillations: {
            title: "Distillations"
          },

          barrels_filling: {
            title: 'Barrels Filling'
          }
        },
        category: {
          id: 'select:category',
          label: 'Category',
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        item_name: {
          id: 'input:item_name',
          label: 'Item Name',
          changed: false,
          readonly: false
        },
        lot_number: {
          id: 'input:lot_number',
          label: 'Lot Number',
          changed: false,
          readonly: false
        },
        amount_remaining: {
          id: 'input:amount_remaining',
          label: 'Amount Remaining',
          changed: false,
          readonly: true
        },

        created: {
          id: 'input:created',
          label: 'Created',
          changed: false,
          readonly: true
        },
        modified: {
          id: 'input:modified',
          label: 'Modified',
          changed: false,
          readonly: true
        }
      },
      data: {
        id: '',
        category: {
          id: '',
          label: ''
        },
        item_name: '',
        lot_number: '',
        amount_remaining: ''
      },
      dropdowns: {
        orderNumbers: [],
        storageLocations: []
      },
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: 'HID',
          showChildRowToggler: false,
          showEmpty: true,
          filterable: [],
          columns: [
            'HID',
            'Order Item ID',
            'Order Number',
            'Order Date',
            'Ordered By',
            'Total Price',
            'Total Amount',
            'Delivery Status',

            'Est Arrival Date',
            'Date Received',
            'Lot Number',
            'Storage Location',
            'Total Received',
            'Lot Amount Received',
            'Amount Remaining',
            'Amount Adjusted Loss',
            'Status'
          ],

          editableColumns: [],

          dropdownColumns: [],
          perPage: 50,
          //showCustomActions: true,
          //showActions: true,
          showChildRows: true,
          selectableRows: this.selectableRows,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback
        },
        childRow: {
          showDescription: false
        }
      }
    }
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created () {
    this.initialize()
  },
  mounted: function () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    async initialize (_id) {
      this.itemId = _id ? _id : this.id
      //this.lot_number = this.$route.query.lot_number  // get lot number from query string

      console.log(this.itemId)

      this.mode = this.$constants.FORM_MODE.VIEW

      this.data.id = this.itemId

      this.loadDictionaries()

      this.loadData(this.itemId)
    },
    onMashesLoad (count) {
      if (count > 0) this.controls.tabs.mashes.title = `Mashes (${count})`
    },
    onFermentationsTableLoad(count) {
      if (count > 0)
        this.controls.tabs.fermentations.title = `Fermentations (${count})`;
    },
    onDistillationsTableLoad(count) {
      if (count > 0)
        this.controls.tabs.distillations.title = `Distillations (${count})`;
    },    
    onOrdersLoad (count) {
      if (count > 0) this.controls.tabs.orders.title = `Purchases (${count})`
    },
    onBarrelFillingLoad (count) {
      if (count > 0)
        this.controls.tabs.barrels_filling.title = `Barrels Filling (${count})`
    },

    loadDictionaries () {
      /*
      let self = this

      const categories = () => {
        this.$api
          .get('dictionaries/inventory/item-categories')
          .then(response => {
            self.controls.category.options = response.map(item => {
              return {
                id: item.id,
                label: item.name
              }
            })
          })
      }

      Promise.all([categories()])
      */
    },
    edit () {},

    async loadData (id) {
      let self = this

      this.isLoading = true

      try {
        //let response = await this.$api.get(`inventory/item/${id}`)
        let response = await this.$api.post(`inventory/inventory-details`, {
          id: id,
          lot_number: this.lot_number
        })

        console.log('loadData', response)
        this.isLoading = false

        if (self.$_.isEmpty(response)) return

        this.data.id = response['ID']

        this.data.item_name = response['Item Name']

        this.data.lot_number = response['Lot Number']
        this.data.amount_remaining = response['Amount Remaining']

        this.data.category = {
          id: response['item_category'],
          label: response['Category']
        }

        this.data.created = response['Created']
        this.data.modified = response['Modified']

        this.drawDataTable(this.lot_number)
      } catch (error) {
        console.log(error)

        this.$form.makeToastError(error)
      } finally {
        this.isLoading = false
      }
    },
    async drawDataTable (lot_number) {
      this.dataTable.isLoading = true

      let response = await this.$api.post('inventory/items/history', {
        lot_number: lot_number
      })

      this.rawData = response

      this.dataTable.isLoading = false

      this.dataTable.dataSet = this.rawData
    },
    toggleDescription (id) {
      this.$refs.dataTable.toggleChildRow(id)
    },
    onDescriptionBlur (row) {
      this.onInlineUpdate({
        id: row['ID'],
        column: 'Notes',
        value: row['Notes']
      })
    }
  },
  watch: {}
}
</script>

<style scoped></style>
